import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Dropdown, Menu, Modal, Row, Typography } from 'antd'
import { sortBy, startCase } from 'lodash'

import CNModalTitleMenu from 'App/components/Booking/General/CNModalTitleMenu'
import ViewTripDocumentsButtonModal from 'App/components/Booking/General/ViewTripDocumentsButtonModal'
import JobsMaps from 'App/components/Transport/Components/VehicleMap/JobsMaps'
import { GET_WAYBILL_TEMPLATES, TRIP_AND_LEGS_QUERY } from 'App/components/Transport/Schemas/schema'
import styles from './TripModal.module.css'

import type { Job, TransportJob, Trip, TripLocations } from 'App/types/graphql'

interface TripModalProps {
  jobs: Job[]
  client: any
  closeModal: any
  selectedRow: Trip
  openModal: boolean
  tripLocations: TripLocations
}

const TripModal = ({
  selectedRow,
  client,
  openModal,
  closeModal,
  tripLocations,
  jobs
}: TripModalProps) => {
  const { t } = useTranslation()
  const job = jobs.find(j => j?.uuid === selectedRow?.jobUuid)
  const { data } = useQuery(TRIP_AND_LEGS_QUERY, {
    client,
    variables: {
      input: { limit: 100, tripUuids: [selectedRow?.uuid] }
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: templates } = useQuery(GET_WAYBILL_TEMPLATES, {
    client,
    variables: {
      q: 'WAYBILL_SLIP'
    }
  })

  const tripData = data?.transportJobs?.rows
  const addressFrom = tripData?.map((trip: any) => {
    const { address1, address2, address3, address4, district, postCode } = trip.from
    return [address1, address2, address3, address4, district, postCode].filter(Boolean).join(', ')
  })

  const addressTo = tripData?.map((trip: any) => {
    const { address1, address2, address3, address4, district, postCode } = trip.to
    return [address1, address2, address3, address4, district, postCode].filter(Boolean).join(', ')
  })

  const groupedJobs = useMemo(() => {
    const t = (data?.transportJobs?.rows || []).reduce(
      (acc: Record<string, TransportJob[]>, tj: TransportJob) => {
        if (!tj?.start) return acc

        const found = acc[tj?.jobNo || '']

        if (!Array.isArray(found)) {
          acc[tj.jobNo || ''] = []
        }

        acc[tj.jobNo || ''].push(tj)

        return acc
      },
      {}
    )

    return sortBy(Object.values(t), t => t?.[0].jobNo)
  }, [data])

  const jobTripUuids = groupedJobs.map((gj: any) => ({ tripUuids: gj.map(t => t.tripUuid) }))

  const Fields = ({ title, description }: { title: string; description: any }) => {
    return (
      <div className={styles.field}>
        <Typography className={styles.key}>{title}</Typography>
        <span>:</span>
        <Typography.Text copyable={!!description} className={styles.value} style={{ margin: 0 }}>
          {description ?? ''}
        </Typography.Text>
      </div>
    )
  }

  const DetailsSection = ({ title, data, customContent }) => (
    <div>
      <Typography.Text style={{ fontWeight: 'bold' }}>{title}</Typography.Text>
      {data
        ? Object.entries(data).map(([key, value]) => (
            <Fields key={key} title={startCase(key)} description={String(value)} />
          ))
        : customContent}
    </div>
  )

  const burgerMenu = (
    <Menu>
      <Menu.Item>
        <CNModalTitleMenu
          // @ts-ignore
          jobUuid={job?.uuid}
        />
      </Menu.Item>
      <Menu.Item>
        <ViewTripDocumentsButtonModal
          trip={tripLocations}
          templates={templates?.documentCreatorTemplates?.rows || []}
        />
      </Menu.Item>
    </Menu>
  )

  return (
    <Modal
      width={'90%'}
      visible={openModal}
      onCancel={closeModal}
      title={
        <Row
          type="flex"
          justify="space-between"
          style={{ width: '100%', paddingTop: 10, paddingRight: 40 }}
        >
          <Typography.Text strong>{t('common.tripInformation')}</Typography.Text>
          <Dropdown overlay={burgerMenu}>
            <MenuOutlined style={{ fontSize: 14, cursor: 'pointer', color: '#858585' }} />
          </Dropdown>
        </Row>
      }
      footer={
        <Row type="flex" justify="end">
          <Button type="primary" onClick={closeModal}>
            {t('common.close')}
          </Button>
        </Row>
      }
    >
      {tripData?.map((i: TransportJob) => (
        <div className={styles.container} key={i.uuid}>
          <div className={styles.leftContainer}>
            <div>
              <Typography.Text strong style={{ marginBottom: '5px' }}>
                {t('common.from')} :
              </Typography.Text>
              <div style={{ marginLeft: '30px', width: '65%' }}>
                <Typography.Text copyable={i.from.name} strong>
                  {i.from.name}
                </Typography.Text>
                <br />
                <Typography.Text>
                  {addressFrom}({i.from.areaCode}) [{i.from.zone}]
                  <br />
                  {i.from.phone && `Contact: ${i.from.phone}`}
                </Typography.Text>
              </div>
            </div>

            <div>
              <Typography.Text strong style={{ marginBottom: '5px' }}>
                {t('common.to')} :
              </Typography.Text>
              <div style={{ marginLeft: '30px', width: '65%' }}>
                <Typography.Text copyable={i.to.name} strong>
                  {i.to.name}
                </Typography.Text>
                <br />
                <Typography.Text>
                  {addressTo}({i.to.areaCode}) [{i.to.zone}]
                  <br />
                  {i.to.phone && `Contact: ${i.to.phone}`}
                </Typography.Text>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '30px', marginTop: '50px' }}>
              <DetailsSection
                title={t('booking.jobDetails')}
                data={tripData && tripData.length > 0 ? tripData[0].jobDetails : null}
                customContent={undefined}
              />
              {Array.isArray(selectedRow?.details) ? null : (
                <DetailsSection
                  title={t('booking.tripDetails')}
                  data={selectedRow?.details}
                  customContent={undefined}
                />
              )}
              <DetailsSection
                title={t('common.otherInformation')}
                customContent={
                  selectedRow ? (
                    <div
                      key={selectedRow.uuid}
                      style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}
                    >
                      <Fields title="Remarks" description={selectedRow.remarks} />
                      <Fields title="Status" description={selectedRow.status} />
                      <Fields title="Seal" description={selectedRow.seal} />
                      <Fields title="Reference" description={selectedRow.references?.join(', ')} />
                    </div>
                  ) : null
                }
                data={undefined}
              />
            </div>
          </div>

          <div className={styles.rightContainer}>
            <>
              <Typography.Text strong>{t('booking.driverDetails')}</Typography.Text>
              <Fields title="Job No" description={i.jobNo} />
              <Fields title="Vehicle Code" description={i.driverName} />
              <Fields title="Job No" description={i.vehicleCode} />
            </>

            <div className={styles.map}>
              <JobsMaps jobTripUuids={jobTripUuids} client={client} showScrollZoom={false} />
            </div>
          </div>
        </div>
      ))}
    </Modal>
  )
}
export default TripModal
