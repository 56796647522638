import { gql } from '@/types'

export const UPDATE_TRIPS_MUTATION = gql(`
  mutation updateTrips($input: UpdateTripsInput) {
    updateTrips(input: $input) {
      success
    }
  }
`)

export const UPDATE_JOB_MUTATION = gql(`
  mutation updateJob($input: UpdateJobInput) {
    updateJob(input: $input) {
      uuid
    }
  }
`)
