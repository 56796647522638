import { gql } from '@/types'

export const ADD_TRIP = gql(`
  mutation addTrip($input: CreateTripInput) {
    createTrip(input: $input) {
      uuid
    }
  }
`)

export const DELETE_TRIP = gql(`
  mutation deleteTrip($uuid: UUID!) {
    deleteTrip(uuid: $uuid) {
      success
      message
    }
  }
`)
