import { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { ApolloClient, gql, useQuery } from '@apollo/client'
import { Col, Layout, Row, Spin } from 'antd'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import moment from 'moment'

import useFeatureFlag from '@/hooks/useFeatureFlag'
import useGlobalCompanyStore from '@/store/globalCompany'
import BookingJobsTable from 'App/components/Workflow/Section/Extras/BookingJobsTable'
import { Brief } from 'App/components/Workflow/Styled'
import { useBookingStore } from 'App/store/booking'
import { TableHeaderButtonText } from 'App/styles/TableHeaderButtonText'
import { DynamicFieldQuery, DynamicFieldType, JobStatus } from 'App/types/graphql'
import Section from '../Section'
import DocumentGeneratorSection from './DocumentGeneratorSection'
import JobAddAction from './JobAddAction'
import JobsDocumentCreatorOverview from './JobsDocumentCreatorOverview'
import { FieldHeader, FieldValueTypography } from './Labels'
import Schedules from './Schedules'

import type { Booking, DynamicField } from 'App/types/graphql'

type GeneralInfoProps = {
  booking: Booking
  client: ApolloClient<any>
}

export const VIEW_BOOKING_TYPE_GQL = gql`
  query bookingTypeBookingSummary($code: String) {
    bookingType(code: $code) {
      dynamicFields {
        key
        type
        query
        control
      }
    }
  }
`

const GeneralInfo = ({ booking, client }: GeneralInfoProps) => {
  const { t } = useTranslation()

  const expandTrips = useBookingStore.use.expandTrips()
  const setExpandTrips = useBookingStore.use.setExpandTrips()
  const hideDeleted = useBookingStore.use.hideDeletedJob()
  const setHideDeleted = useBookingStore.use.setHideDeletedJob()

  const [bookingData, setBookingData] = useState(booking)
  const [hasDeleted, setHasDeleted] = useState(false)
  const showAllTrips = () => {
    setExpandTrips(!expandTrips)
  }

  useEffect(() => {
    if (!booking) return
    const filteredJobs = booking.jobs?.filter(job => job?.status !== JobStatus.Cancelled)
    setHasDeleted(booking.jobs?.length !== filteredJobs?.length)
    setBookingData(hideDeleted ? { ...booking, jobs: filteredJobs } : booking)
  }, [hideDeleted, booking])

  const [dynamicFields, setDynamicFields] = useState<any[]>([])

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { isEnabled: isDocumentGeneratorEnabled } = useFeatureFlag({
    client,
    name: 'DOCUMENT GENERATOR',
    baseCompanyUuid: selectedGlobalCompany.uuid
  })

  const { loading, refetch: refetchBooking } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    variables: { code: booking.type },
    client,
    fetchPolicy: 'cache-first',
    onCompleted: data => setDynamicFields(data?.bookingType?.dynamicFields || [])
  })

  if (loading) return <Spin />

  const compulsoryFields = [
    {
      key: 'no'
    },
    {
      key: 'billTo',
      query: DynamicFieldQuery.Company
    }
  ]

  const getData = (key: string, dynamicField: DynamicField) => {
    if (dynamicField.type === DynamicFieldType.Date) {
      const splitKey = key.split('.')
      const dateKey = splitKey.length > 1 ? splitKey[1] : key
      const chronologies = get(booking, 'chronologies') || []
      const chron = chronologies.find(c => c?.type === dateKey)
      return moment(chron?.date).format('LLL')
    }

    if (dynamicField.query === DynamicFieldQuery.Company) {
      const company = get(booking.details, key) || get(booking, key)

      if (!company) {
        return 'No Company'
      }

      return `[${company.code || ''}] ${company.name || ''}`
    }

    if (
      // @ts-ignore
      [DynamicFieldQuery.Place, DynamicFieldQuery.Port].includes(dynamicField.query)
    ) {
      return `${booking.details.name}`
    }

    if (Array.isArray(booking[key])) {
      return (booking[key] || []).join(', ')
    }

    return booking[key] || booking.details[key]
  }

  const renderFields = (dynamicField: DynamicField) => {
    const data = getData(dynamicField.key || '', dynamicField)
    return (
      <Row key={dynamicField.key}>
        <FieldHeader>{startCase(dynamicField.key || '')} :</FieldHeader>
        <FieldValueTypography copyable={!!data}>{data}</FieldValueTypography>
      </Row>
    )
  }

  return (
    <>
      <Layout hasSider style={{ background: 'transparent' }}>
        <Layout.Content>
          <Row gutter={[16, { xs: 20, lg: 48 }]} style={{ marginTop: 1 }}>
            <Col xs={24} sm={12}>
              {compulsoryFields.map(renderFields)}
            </Col>
            <Col xs={24} sm={12}>
              {dynamicFields.map(renderFields)}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>

      <Section
        title={
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Row type="flex" style={{ gap: '10px' }}>
              Jobs & Trips
              <JobAddAction
                bookingUuid={booking?.uuid}
                shipmentType={booking.details.shipmentType}
              />
            </Row>
            <Row type="flex" style={{ textAlign: 'right', gap: '10px', alignItems: 'center' }}>
              {/* @ts-ignore */}
              <Brief onClick={setHideDeleted}>
                {hasDeleted
                  ? hideDeleted
                    ? `${t('common.showDeleted')}`
                    : `${t('common.hideDeleted')}`
                  : ''}
              </Brief>
              <span onClick={showAllTrips}>
                <TableHeaderButtonText>
                  {expandTrips ? 'Minimize Jobs' : 'Expand Jobs'}{' '}
                </TableHeaderButtonText>
              </span>
            </Row>
          </Row>
        }
      >
        <JobsDocumentCreatorOverview
          booking={bookingData}
          expandAllTrips={expandTrips}
          hideDeleted={hideDeleted}
        />
      </Section>

      <Section>
        <BookingJobsTable booking={bookingData} query={{}} refetchBooking={refetchBooking} />
      </Section>

      <Section title="Schedules">
        <Schedules booking={booking} client={client} refetchBooking={refetchBooking} />
      </Section>

      {isDocumentGeneratorEnabled && (
        <DocumentGeneratorSection bookingUuid={booking?.uuid} client={client} />
      )}
    </>
  )
}

export default withApollo(GeneralInfo)
