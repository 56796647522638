import { compose } from 'react-apollo'
import { RedoOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { withHandlers, withState } from 'recompose'
import styled from 'styled-components'

import RolloverModal from './RolloverModal'

const state = withState('isModalVisible', 'setIsModalVisible', false)

const StyledButton = styled.span`
  background-color: transparent;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`

const handlers = withHandlers({
  onActionClick: props => () => {
    props.setIsModalVisible(true)
  },
  onModalClose: props => () => {
    props.setIsModalVisible(false)
  }
})

const enhance = compose(state, handlers)

const RolloverAction = props => {
  return (
    <>
      <Tooltip title="Rollover">
        <StyledButton
          style={{ marginLeft: '10px', color: undefined }}
          onClick={props.onActionClick}
        >
          <RedoOutlined />
        </StyledButton>
      </Tooltip>
      {props.isModalVisible && <RolloverModal job={props.job} onClose={props.onModalClose} />}
    </>
  )
}

RolloverAction.propTypes = {
  isModalVisible: PropTypes.bool,
  onActionClick: PropTypes.func,
  onModalClose: PropTypes.func,

  job: PropTypes.object
}

export default enhance(RolloverAction)
