import { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useMutation } from '@apollo/client'
import { Button, Col, Form, Modal, notification, Row } from 'antd'
import { pick, uniq } from 'lodash'

import { getTrimmedDetails } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import JobForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import RolloverAction from '@/components/Workflow/RolloverAction'
import useJobTypes from '@/hooks/useJobTypes'
import { useBookingStore } from '@/store/booking'
import { JobStatus, TripStatus } from '@/types/graphql'
import { logger } from '@/utils/logger'
import JobDeleteAction from './JobDeleteAction'
import JobUndeleteAction from './JobUndeleteAction'
import { UPDATE_JOB_MUTATION, UPDATE_TRIPS_MUTATION } from './schema'

import type { Job, UpdateJobInput } from '@/types/graphql'

interface JobEditActionProps {
  job: Job
  client: ApolloClient<object>
  refetch: any
}

const JobEditAction = (props: JobEditActionProps) => {
  const { job, client, refetch } = props
  const { t } = useTranslation()

  const [jobUuid] = useState(job.uuid)
  const [jobsArray, setJobsArray] = useState([job])
  const [displayModal, setDisplayModal] = useState(false)
  const [hasDeleted, setHasDeleted] = useState(false)
  const { requestJobTypes, jobTypes } = useJobTypes(client)

  const hideDeletedTrip = useBookingStore.use.hideDeletedTrip()
  const setHideDeletedTrip = useBookingStore.use.setHideDeletedTrip()

  const [updateJobMutation] = useMutation(UPDATE_JOB_MUTATION, { client })
  const [updateTripsMutation] = useMutation(UPDATE_TRIPS_MUTATION, { client })

  const handleDisplayModal = () => setDisplayModal(true)
  const handleModalCancel = () => setDisplayModal(false)

  const handleModalOk = async () => {
    const job = jobsArray[0]
    const tripsInput = job?.trips?.map(trip => {
      return {
        ...pick(trip, [
          'uuid',
          'fromUuid',
          'toUuid',
          'remarks',
          'references',
          'seal',
          'status',
          'type',
          'details'
        ])
      }
    })

    const formattedJob: UpdateJobInput = {
      details: getTrimmedDetails(
        'job',
        job.details,
        jobTypes.find(j => j.code === job.type)
      ),
      no: job.no,
      remarks: job.remarks,
      reference: job.reference,
      uuid: jobUuid,
      type: job.type,
      tripFormat: job.tripFormat
    }

    try {
      await updateJobMutation({ variables: { input: { ...formattedJob } } })
      console.log(tripsInput)
      await updateTripsMutation({
        variables: {
          input: {
            jobUuid,
            trips: tripsInput
          }
        }
      })

      notification.success({
        message: 'Submit Notice',
        description: 'Successfully updated Job'
      })

      refetch()
      setDisplayModal(false)
    } catch (error: any) {
      logger.error('Update Job Error', error)
      return notification.error({
        message: 'Error',
        description: error.message.replace('GraphQL error: ', '')
      })
    }
  }

  useEffect(() => {
    const jobTypesKeys = uniq(jobsArray.map(job => job.type)).filter(Boolean) as string[]

    if (!jobTypesKeys.length) return

    requestJobTypes(jobTypesKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsArray]) // dont add requestJobTypes as a dependency, it will cause infinite loop

  useEffect(() => {
    if (!job) return

    const filtered = job.trips?.filter(
      // @ts-ignore
      trip => ![TripStatus.Deleted, TripStatus.Cancelled].includes(trip?.status)
    )
    setHasDeleted(job.trips?.length === filtered?.length ? false : true)
    setJobsArray(hideDeletedTrip ? [job] : [{ ...job, trips: filtered }])
  }, [hideDeletedTrip, job])

  const updateJobs = (jobs: Job[]) => setJobsArray(jobs)
  const HeaderModal = ({ setHideDeleted, hideDeleted }) => (
    <Row type="flex" justify="space-between" style={{ paddingRight: '30px', marginTop: 5 }}>
      Edit Job
      <div>
        {hasDeleted && (
          <Button type="link" onClick={() => setHideDeleted(state => !state)}>
            {hideDeleted ? t('common.hideDeleted') : t('common.showDeleted')}
          </Button>
        )}
        <RolloverAction job={job} />
      </div>
    </Row>
  )

  return (
    <>
      <Button size="small" icon="edit" onClick={handleDisplayModal} type="ghost" />

      {displayModal && (
        <div onClick={e => e.stopPropagation()}>
          <Modal
            visible
            width={'95%'}
            title={
              <HeaderModal setHideDeleted={setHideDeletedTrip} hideDeleted={hideDeletedTrip} />
            }
            onCancel={handleModalCancel}
            footer={
              <Row>
                <Col span={6} style={{ textAlign: 'left' }}>
                  {job?.status === JobStatus.Cancelled ? (
                    <JobUndeleteAction job={job} />
                  ) : (
                    <JobDeleteAction job={job} />
                  )}
                </Col>

                <Col span={18}>
                  <Button onClick={handleModalCancel}>Cancel</Button>
                  <Button type="primary" onClick={handleModalOk}>
                    {t('common.update')}
                  </Button>
                </Col>
              </Row>
            }
          >
            <Form>
              {jobsArray.map((job, index) => (
                <JobForm
                  key={job.uuid || index}
                  // @ts-ignore
                  currentJob={job}
                  jobIndex={index}
                  allJobs={jobsArray}
                  updateJobs={updateJobs}
                  mode={FormMode.Edit}
                />
              ))}
            </Form>
          </Modal>
        </div>
      )}
    </>
  )
}

export default withApollo(JobEditAction)
